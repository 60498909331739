import React from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

export default function ErrorPage() {
	const error = useRouteError();
	let status: { code: number | undefined; message: string } = {
		code: undefined,
		message: 'Ops! Algo não correu bem.',
	};

	if (isRouteErrorResponse(error)) {
		if (error.status === 404) {
			status = {
				code: 404,
				message: 'Página não encontrada!',
			};
		}

		if (error.status === 401) {
			status = {
				code: 401,
				message: 'Não autorizado!',
			};
		}

		if (error.status === 503) {
			status = {
				code: 503,
				message: 'Serviços internos indisponíveis!',
			};
		}
	}

	return (
		<div className="flex-grow flex justify-center items-center flex-col text-center">
			<section>
				<img className="mx-auto mb-20 text-white" src="/imgs/error.svg" alt="404" />
				<figcaption>
					<h1 className="mb-5 text-6xl font-semibold text-light-extra dark:text-title-dark max-ssm:text-5xl max-xs:text-4xl">
						{status.code}
					</h1>
					<p className="mb-6 text-lg font-medium text-body dark:text-subtitle-dark max-xs:text-base">
						{status.message}
					</p>
					<button
						type="button"
						className="bg-primary border-primary hover:bg-primary-hbr rounded-6 px-[20px] capitalize text-[15px] h-11 inline-flex items-center justify-center text-white transition duration-300"
					>
						<span>Return Home</span>
					</button>
				</figcaption>
			</section>
		</div>
	);
}
