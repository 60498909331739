import React, { ReactNode } from 'react';
import Button from '../components/Button';
import { IconType } from '@iconscout/react-unicons';

export interface FormGroupProps {
	title?: string;
	titleAlign?: 'left' | 'center' | 'right';
	children: ReactNode;
	action?: FormGroupAction;
}

export interface FormGroupAction {
	icon: IconType;
	action: () => void;
}

function FormGroup({ title, titleAlign = 'left', children, action }: FormGroupProps) {
	return (
		<div className="m-0 p-0 relative rounded-2xl shadow-md">
			{title && (
				<div className="h-16 pl-4 pr-4 border-b-[1px] border-gray-200 bg-white flex items-center rounded-t-xl justify-between">
					<span className={`font-bold text-md text-${titleAlign} w-full`}>{title}</span>
					{action && <Button icon={action.icon} style="roundedOutline" onClick={action.action} />}
				</div>
			)}
			<div className={`flex-1 p-4 bg-white ${title ? 'rounded-b-xl' : 'rounded-xl'}`}>{children}</div>
		</div>
	);
}

export default FormGroup;
