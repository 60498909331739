import { useEffect, useState } from 'react';
import useAPI from './useAPI';
import { useAuth } from '../useAuth';

export interface Tenant {
	id: string;
	name: string;
}

export default function useAPITenants() {
	const { user } = useAuth();

	const api = useAPI(true, user?.access_token);
	const [tenants, setTenants] = useState<Tenant[] | undefined>(undefined);
	const [loading, setLoading] = useState(false);

	async function fetchTenants() {
		setTenants(undefined);
		setLoading(true);

		const tenants = await api.get('/profile/tenants');
		setTenants(tenants);
		setLoading(false);
	}

	useEffect(() => {
		fetchTenants();
	}, [user]);

	return { tenants, loading, fetchTenants };
}
