import React from 'react';
import { faChevronDown, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import { useProfile } from '../hooks/useProfile';
import { useNavigate } from 'react-router-dom';

function UserMenu() {
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);
	const { sessionUser } = useProfile();
	const userMenuRef = useRef<HTMLInputElement>(null);

	const menu: {
		title: string;
		action: () => void;
	}[] = [
		{
			title: 'Help',
			action: () => {
				navigate('/help');
			},
		},
	];

	function handleLogout() {
		navigate('/auth/logout');
	}

	useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			if (userMenuRef.current && !userMenuRef.current.contains(event.target as Node)) {
				setOpen(false);
			}
		}

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [userMenuRef, setOpen]);

	if (!sessionUser) return <></>;

	return (
		<div ref={userMenuRef} className="relative">
			<button
				type="button"
				className="flex items-center me-1.5 gap-2 rounded-full md:me-0 group whitespace-nowrap"
				onClick={() => setOpen(!open)}
			>
				<img className="w-8 h-8 rounded-full" src="/imgs/thumbs.png" alt="user face" />
				<div className="hidden xl:block">
					<span className="text-sm">{`${sessionUser.given_name} ${sessionUser.family_name}`}</span>
				</div>
				<FontAwesomeIcon icon={faChevronDown} className="text-gray-400 text-xs" />
			</button>
			<div
				className={`${!open ? 'opacity-0 pointer-events-none visibility-hidden' : 'opacity-100 pointer-events-auto visibility-visible'} fixed z-[1000] m-0 top-12 right-4 w-80 list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg transition-all duration-500 ease-in-out`}
			>
				<div className="flex flex-col max-sm:min-w-full pt-4 px-4 py-3 bg-white shadow-[0_2px_8px_rgba(0,0,0,.15)] rounded-4">
					<figure className="flex items-center rounded-lg bg-gray-100 py-5 px-6 mb-3 gap-4">
						<img className="w-8 h-8 rounded-full bg-gray-200" src="/imgs/thumbs.png" alt="user" />
						<figcaption className="flex flex-col overflow-hidden">
							<span className="text-sm">
								{sessionUser.given_name} {sessionUser.family_name}
							</span>
							<span className="text-xs">{sessionUser.email}</span>
						</figcaption>
					</figure>
					<ul className="m-0 pb-[10px] overflow-x-hidden overflow-y-auto scrollbar bg-transparent max-h-[230px]">
						{menu.map((item) => (
							<li key={item.title} className="w-full">
								<div className="p-0 hover:bg-primary/10 rounded-4 group">
									<button
										className="inline-flex items-center text-gray-900 group-hover:text-primary group-hover:ps-6 w-full px-2.5 py-3 text-sm transition-all ease-in-out gap-4"
										onClick={item.action}
									>
										{item.title}
									</button>
								</div>
							</li>
						))}
					</ul>
					<button
						className="flex items-center justify-center text-sm font-medium bg-gray-200 h-12 text-light hover:text-primary dark:hover:text-subtitle-dark dark:text-title-dark mx-[-15px] mb-[-15px] rounded-b-6 gap-[6px]"
						onClick={handleLogout}
					>
						<FontAwesomeIcon icon={faPowerOff} />
						Logout
					</button>
				</div>
			</div>
		</div>
	);
}

export default UserMenu;
