import React from 'react';
import { UilSpinner } from '@iconscout/react-unicons';
import { useProfile } from '../hooks/useProfile';

export default function HomePage() {
	const { sessionUser, tenant, loading } = useProfile();
	return (
		<section className="flex-grow flex flex-col p-4">
			{loading && <UilSpinner className="animate-spin" />}
			{sessionUser && (
				<h1 className="text-lg font-bold">Welcome, {`${sessionUser.given_name} ${sessionUser.family_name}`}</h1>
			)}
			{tenant && (
				<h2>
					<span className="font-bold">Connected to ISP:</span> {tenant.name} as{' '}
					<span className="text-primary">{tenant.role}</span>
				</h2>
			)}
		</section>
	);
}
