import { useState, useEffect, useCallback } from 'react';
import { useDebounce } from 'use-debounce';

import { APIError } from '../../shared/api_errors';
import useAPI from './useAPI';
import { useProfile } from '../useProfile';
import { useAuth } from '../useAuth';

const OR_PREFIX = 'or_';

export type SortOrder = 'asc' | 'desc';

export type QueryOperator = 'eq' | 'lt' | 'le' | 'gt' | 'ge' | 'ne' | 'like' | 'ilike' | 'in' | 'not_null' | 'null';

export interface QueryCondition {
	[field: string]: {
		[operator: string]: string;
	};
}

export interface Query {
	[key: string]: QueryCondition | QueryCondition[];
}

export interface Operator {
	field: string;
	op: QueryOperator;
}

export interface Condition {
	operator: Operator;
	value?: string;
}

export interface OrCondition {
	conditions: Array<Operator>;
	value?: string;
}

export function isOrCondition(condition: Condition | OrCondition): condition is OrCondition {
	return (condition as OrCondition).conditions !== undefined;
}

export function generateOrConditionKey(or: OrCondition) {
	const fields = or.conditions.map((condition) => condition.field).join('-');
	return `${OR_PREFIX}${fields}`;
}

// function isOrKey(key: string) {
// 	return key.startsWith(OR_PREFIX);
// }

export interface PaginatedResponse<T> {
	rows: T[];
	records: number;
}

export interface UseCRUDQueryReturn<T> {
	response: PaginatedResponse<T> | null;
	loading: boolean;
	error: APIError | null;
	page: number;
	pageSize: number;
	refetch: (newPage?: number, newPageSize?: number) => Promise<void>;
	setPageSize: (newPageSize: number) => void;
	setPage: (newPage: number) => void;
	sortField?: string;
	sortOrder: SortOrder;
	setOrder: (newSortField: string, newSortOrder: SortOrder) => void;
	query: Query;
	setCondition: (condition: Condition | OrCondition) => void;
	ready: boolean;
	clear: () => void;
}

export class CRUDQueryOptions {
	initialSortField: string | undefined = undefined;
	initialQuery: Query = {};
	initialPage: number = 1;
	initialPageSize: number = 10;
	initialSortOrder: SortOrder = 'asc';
	performFetch: boolean = true;
	emptyQueryFetch: boolean = true;
	usePagination: boolean = true;
	useSorting: boolean = true;

	constructor(options?: Partial<CRUDQueryOptions>) {
		if (options) {
			Object.assign(this, options);
		}
	}
}

function parseQuery(query: Query): Query | undefined {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const newQuery: any = {};

	for (const key in query) {
		const conditionOrArray = query[key];

		if (Array.isArray(conditionOrArray)) {
			// Handle the case where query[key] is an array of QueryCondition (for OR conditions)
			newQuery['or'] = conditionOrArray.map((condition) => {
				const key = Object.keys(condition)[0];
				if (condition[key]['ilike']) {
					return {
						[key]: { ilike: `%${condition[key]['ilike']}%` },
					};
				}
				return condition;
			});
		} else {
			// Handle the case where query[key] is a single QueryCondition (for AND conditions)
			const key = Object.keys(conditionOrArray)[0];
			if (conditionOrArray[key]['ilike']) {
				newQuery[key] = { ilike: `%${conditionOrArray[key]['ilike']}%` };
			} else {
				newQuery[key] = conditionOrArray[key];
			}
		}
	}

	const result = Object.keys(newQuery).length > 0 ? newQuery : undefined;
	console.log(result);
	return result;
}

function useCRUDQuery<T>(endpoint: string, options?: Partial<CRUDQueryOptions>): UseCRUDQueryReturn<T> {
	const { tenant } = useProfile();
	const { user } = useAuth();
	const api = useAPI(true, user?.access_token, tenant);

	const crudOptions = new CRUDQueryOptions(options);

	const [response, setResponse] = useState<PaginatedResponse<T> | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<APIError | null>(null);
	const [page, setPage] = useState<number>(crudOptions.initialPage);
	const [pageSize, setPageSize] = useState<number>(crudOptions.initialPageSize);
	const [sortField, setSortField] = useState<string | undefined>(crudOptions.initialSortField);
	const [sortOrder, setSortOrder] = useState<SortOrder>(crudOptions.initialSortOrder);
	const [query, setQuery] = useState<Query>(crudOptions.initialQuery);

	const [debouncedQuery] = useDebounce(query, 500);

	function handleSetOrder(newSortField: string, newSortOrder: SortOrder) {
		setSortField(newSortField);
		setSortOrder(newSortOrder);
	}

	function handleSetCondition(condition: Condition | OrCondition) {
		const isOr = isOrCondition(condition);
		const queryKey = isOr ? generateOrConditionKey(condition) : condition.operator.field;

		const value = condition.value;

		if (!value) {
			delete query[queryKey];
			setQuery({ ...query });
			return;
		}

		if (!query[queryKey]) {
			query[queryKey] = {};
		}

		if (isOr) {
			query[queryKey] = condition.conditions.map((c) => ({ [c.field]: { [c.op]: value } }));
		} else {
			query[queryKey] = { [condition.operator.field]: { [condition.operator.op]: value } };
		}

		setQuery({ ...query });
	}

	const fetchData = useCallback(async () => {
		if (!tenant || !crudOptions.performFetch) return;
		if (!crudOptions.emptyQueryFetch && !Object.keys(query).length) return;

		setLoading(true);
		setError(null);
		try {
			const parsedQuery = parseQuery(debouncedQuery);
			const searchParams = parsedQuery
				? new URLSearchParams({
						page: `${page}`,
						page_size: `${pageSize}`,
						sort_field: `${sortField || ''}`,
						sort_order: sortOrder,
						criteria: JSON.stringify(parsedQuery),
					})
				: new URLSearchParams({
						page: `${page}`,
						page_size: `${pageSize}`,
						sort_field: `${sortField || ''}`,
						sort_order: sortOrder,
					});

			if (!crudOptions.usePagination) {
				searchParams.delete('page');
				searchParams.delete('page_size');
			}

			if (!crudOptions.useSorting) {
				searchParams.delete('sort_field');
				searchParams.delete('sort_order');
			}

			const endpointQuery = [endpoint, searchParams.toString()].filter((o) => o).join('?');
			const response = await api.get(endpointQuery);
			const result: PaginatedResponse<T> = crudOptions.usePagination
				? (response as PaginatedResponse<T>)
				: ({
						rows: response,
						records: response.length,
					} as PaginatedResponse<T>);
			setResponse(result);
		} catch (error) {
			if (typeof error === 'string') {
				setError(new APIError(error));
			} else if (error instanceof Error) {
				setError(new APIError(error.message));
			} else if (error instanceof APIError) {
				setError(error);
			}
			setResponse(null);
		} finally {
			setLoading(false);
		}
	}, [endpoint, page, pageSize, sortField, sortOrder, debouncedQuery, api.get, tenant, crudOptions.performFetch]);

	const refetch = async (newPage?: number, newPageSize?: number): Promise<void> => {
		newPage && setPage(newPage);
		newPageSize && setPageSize(newPageSize);

		// When a refetch is called without parameters, just to refresh data.
		if (!newPage && !newPageSize) {
			await fetchData();
		}
	};

	const clear = useCallback(async () => {
		setResponse(null);
	}, []);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	return {
		response,
		loading,
		error,
		page,
		pageSize,
		refetch,
		setPageSize,
		setPage,
		sortField,
		sortOrder,
		setOrder: handleSetOrder,
		query,
		setCondition: handleSetCondition,
		ready: !!tenant,
		clear,
	};
}

export default useCRUDQuery;
