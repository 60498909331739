import React, { ReactNode, useEffect, useState } from 'react';
import Sidebar from '../slices/Sidebar';
import Header from '../slices/Header';

interface RootProps {
	children: ReactNode;
}

export default function Full({ children }: RootProps) {
	const [open, setOpen] = useState(false);
	const [fold, setFold] = useState(false);

	const foldingClasses = !fold ? 'ml-72' : 'ml-20';

	function handleSetOpen(value: boolean) {
		setOpen(value);
	}

	function handleSetFold(value: boolean) {
		setFold(value);
		if (value) setOpen(true);
	}

	useEffect(() => {
		function handleResize() {
			if (window.innerWidth >= 1024 && fold && !open) {
				setOpen(true);
			}
		}

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [fold, open, setOpen]);

	return (
		<>
			<Sidebar open={open} setOpen={handleSetOpen} fold={fold} />
			<div
				className={`relative ${foldingClasses} max-lg:ml-0 translate-x-0 duration-300 flex flex-col flex-1 items-stretch h-screen`}
			>
				<div className="flex flex-col flex-grow bg-gray-100">
					<Header open={open} fold={fold} setOpen={handleSetOpen} setFold={handleSetFold} />
					<main className="flex-grow flex w-full">{children}</main>
				</div>
			</div>
		</>
	);
}
