export function isServerError(statusCode: number) {
	return statusCode >= 500 && statusCode <= 599;
}

export function isBadRequest(statusCode: number) {
	return statusCode >= 400 && statusCode <= 499;
}

export enum APIErrorClass {
	InternalError,
	BadRequest,
	Unknown,
}

export interface ValidationError {
	type: string;
	loc: string[];
	msg: string;
	input: unknown;
}

export class APIError {
	constructor(
		public message: string,
		public status_code?: number,
		public validations_error?: ValidationError[],
	) {}

	error_type = (): APIErrorClass => {
		if (this.status_code) {
			if (isServerError(this.status_code)) return APIErrorClass.InternalError;
			if (isBadRequest(this.status_code)) return APIErrorClass.BadRequest;
		}

		return APIErrorClass.Unknown;
	};
}
