import { useState } from 'react';
import useAPI from './useAPI';

import { APIError } from '../../shared/api_errors';
import { useProfile } from '../useProfile';
import { useAuth } from '../useAuth';

export default function useAPISubscription() {
	const { tenant } = useProfile();
	const { user } = useAuth();
	const api = useAPI(true, user?.access_token, tenant);

	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<APIError | undefined>();

	async function handleAPICall(endpoint: string) {
		if (!tenant) {
			setLoading(false);
			return;
		}

		setLoading(true);

		try {
			const result = await api.put(endpoint);
			setError(undefined);
			setLoading(false);
			return result;
		} catch (error) {
			if (typeof error === 'string') {
				setError(new APIError(error));
			} else if (error instanceof Error) {
				setError(new APIError(error.message));
			} else if (error instanceof APIError) {
				setError(error);
			}
		} finally {
			setLoading(false);
		}
	}

	async function activate(subscriptionId: string) {
		return handleAPICall(`/service/subscriptions/${subscriptionId}/activate`);
	}

	async function terminate(subscriptionId: string) {
		return handleAPICall(`/service/subscriptions/${subscriptionId}/terminate`);
	}

	async function toggleHold(subscriptionId: string) {
		return handleAPICall(`/service/subscriptions/${subscriptionId}/hold/toggle`);
	}

	return { error, loading, activate, terminate, toggleHold };
}
