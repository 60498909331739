import React from 'react';

import { DataTableField } from '../../components/DataTable';
import { Filter } from '../../slices/Filters';

import CRUDPage from '../CRUDPage';
import { Speed, formatMikrotikRateLimit } from '../../shared/models/service/Speed';

const fields: Array<DataTableField<Speed>> = [
	{ title: 'Description', property: 'description' },
	{ title: 'Rate Limit', property: 'rate-limit', extractor: (row: Speed) => formatMikrotikRateLimit(row) },
];
const endpoint = '/service/speed';
const formUrl = '/service/speeds/new';

export default function SpeedPage() {
	const filters: Array<Filter> = [
		{
			id: 'description',
			label: 'Description:',
			operator: {
				field: 'description',
				op: 'ilike',
			},
		},
	];

	return (
		<CRUDPage
			title="Speed"
			endpoint={endpoint}
			initialSortField="description"
			fields={fields}
			filters={filters}
			formUrl={formUrl}
			useDefaultActions
		/>
	);
}
