import { useEffect, useState } from 'react';
import useAPI from './useAPI';

import { NASConnection, NASPool } from '../../shared/models/infra/NAS';
import { APIError } from '../../shared/api_errors';
import { useProfile } from '../useProfile';
import { useAuth } from '../useAuth';

export class APINASOptions {
	mustFetchPools: boolean = false;
	mustFetchConnectionStatus: boolean = false;

	constructor(options?: Partial<APINASOptions>) {
		if (options) {
			Object.assign(this, options);
		}
	}
}

export default function useAPINAS(nas_id: string | null, options?: Partial<APINASOptions>) {
	const { mustFetchConnectionStatus, mustFetchPools } = new APINASOptions(options);

	const { tenant } = useProfile();
	const { user } = useAuth();
	const api = useAPI(true, user?.access_token, tenant);
	const [pools, setPools] = useState<NASPool[] | undefined>(undefined);
	const [connectionStatus, setConnectionStatus] = useState<NASConnection[] | undefined>(undefined);

	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<APIError | undefined>();

	async function fetchPools() {
		if (!nas_id || !tenant) {
			setLoading(false);
			setPools(undefined);
			return;
		}

		setPools(undefined);
		setLoading(true);

		try {
			const result = await api.get(`/infra/nas/${nas_id}/pools`);
			setError(undefined);
			setPools(result);
			setLoading(false);
			return result;
		} catch (error) {
			if (typeof error === 'string') {
				setError(new APIError(error));
			} else if (error instanceof Error) {
				setError(new APIError(error.message));
			} else if (error instanceof APIError) {
				setError(error);
			}
		} finally {
			setLoading(false);
		}
	}

	async function checkConnectionStatus() {
		if (!nas_id) {
			setConnectionStatus(undefined);
			setLoading(false);
			return;
		}

		try {
			setLoading(true);
			const result = await api.get(`/infra/nas/connection/check/${nas_id}`);
			setConnectionStatus(result);
		} catch (error) {
			setConnectionStatus(undefined);
			if (typeof error === 'string') {
				setError(new APIError(error));
			} else if (error instanceof Error) {
				setError(new APIError(error.message));
			} else if (error instanceof APIError) {
				setError(error);
			}
		} finally {
			setLoading(false);
		}
	}

	function clear() {
		setPools(undefined);
		setConnectionStatus(undefined);
		setLoading(false);
		setError(undefined);
	}

	function fetch() {
		mustFetchPools && fetchPools();
		mustFetchConnectionStatus && checkConnectionStatus();
	}

	useEffect(() => {
		fetch();
	}, [nas_id, mustFetchPools, mustFetchConnectionStatus]);

	return { pools, connectionStatus, loading, fetchPools, error, clear, refresh: fetch };
}
