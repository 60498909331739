import React from 'react';

import { DataTableField } from '../components/DataTable';
import { Filter } from '../slices/Filters';

import CRUDPage from './CRUDPage';
import {
	Subscription,
	SubscriptionStatus,
	getSubscriptionStatus,
	subscriptionStatuses,
} from '../shared/models/Subscription';
import { applyMask } from '../components/Input';
import { formatMikrotikRateLimit } from '../shared/models/service/Speed';

const fields: Array<DataTableField<Subscription>> = [
	{ title: 'Status', property: 'status', extractor: (row) => `${getSubscriptionStatus(row)}` },
	{ title: 'Customer', property: 'client', extractor: (row: Subscription) => row.client.name },
	{
		title: 'Connectivity',
		property: 'connectivity_user',
		extractor: (row: Subscription) => `${row.connectivity_user} \\ ${row.connectivity_password}`,
	},
	{ title: 'Plan', property: 'service_plan_name' },
	{
		title: 'Price',
		property: 'service_plan_price',
		align: 'right',
		extractor: (row: Subscription) => applyMask(row.service_plan_price.toString()),
	},
	{ title: 'Recurrency', property: 'service_plan_recurrency_interval' },
	{
		title: 'Speed',
		property: 'connectivity_speed',
		extractor: (row) => (!row.held_at ? formatMikrotikRateLimit(row.connectivity_speed) : 'Connection Suspended'),
	},
	{
		title: 'NAS',
		property: 'infra_nas',
		extractor: (row) => {
			const pool = row.infra_nas_pool;
			const nas = row.infra_nas_pool.infra_nas;
			if (!nas) return 'No pool found';
			let cell = `${nas.name} (${nas.ip}), Pool: ${pool.name}`;
			if (row.connectivity_ip) cell = `${cell} IP: ${row.connectivity_ip}`;
			return cell;
		},
	},
];
const endpoint = '/service/subscriptions';
const formUrl = '/subscriptions/new';

export default function SubscriptionPage() {
	const filters: Array<Filter> = [
		{
			id: 'status',
			label: 'Status:',
			options: Object.values(subscriptionStatuses).map((value) => ({ key: value, label: value })),
			initialValue: 'Active' as SubscriptionStatus,
			operator: (value?: string) => {
				const status: SubscriptionStatus = value as SubscriptionStatus;
				if (status === 'Active')
					return [
						{ field: 'started_at', op: 'not_null' },
						{ field: 'canceled_at', op: 'null' },
					];
				if (status === 'Pending') return [{ field: 'started_at', op: 'null' }];
				if (status === 'Terminated')
					return [
						{ field: 'started_at', op: 'not_null' },
						{ field: 'canceled_at', op: 'not_null' },
					];
			},
		},
		{
			id: 'customer',
			label: 'Customer:',
			operator: {
				field: 'client.name',
				op: 'ilike',
			},
		},
		{
			id: 'nas-name',
			label: 'NAS name:',
			operator: {
				field: 'infra_nas.name',
				op: 'ilike',
			},
		},
		{
			id: 'nas-ip',
			label: 'NAS ip:',
			operator: {
				field: 'infra_nas.ip',
				op: 'ilike',
			},
		},
	];

	return (
		<CRUDPage
			title="Subscriptions"
			endpoint={endpoint}
			initialSortField="created_at"
			fields={fields}
			filters={filters}
			formUrl={formUrl}
			useDefaultActions
		/>
	);
}
