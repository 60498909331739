import React, { ReactNode } from 'react';
import Breadcrumbs from '../slices/Breadcrumbs';
import Alert from '../slices/Alert';
import { APIError, APIErrorClass } from '../shared/api_errors';

interface WrapperPageProps {
	title: string;
	error?: APIError;
	children: ReactNode;
}

export default function WrapperPage({ title, error, children }: WrapperPageProps) {
	return (
		<section className="flex flex-col flex-grow w-full">
			<div className="m-4 flex flex-col gap-4">
				<div className="mb-4 flex items-center justify-between">
					<div>
						<h1 className="text-xl font-bold">{title}</h1>
					</div>
					<div className="flex flex-wrap justify-center">
						<Breadcrumbs />
					</div>
				</div>
				{error && (
					<Alert
						type={error.error_type() === APIErrorClass.InternalError ? 'danger' : 'warning'}
						title="Ops! Something went wrong."
						message={
							error.error_type() === APIErrorClass.InternalError
								? 'An internal problem occurred and it was not possible to process your request.'
								: error.message
						}
					/>
				)}
			</div>
			<div className="flex flex-col gap-4 flex-grow">{children}</div>
		</section>
	);
}
