import React from 'react';

import { CRUDFormGroupType } from '../components/CRUDFormGroup';
import { useCRUDFormPage } from '../hooks/useCRUDForm';
import { BeforeSubmitType } from '../hooks/useCRUDForm';
import GenericCRUDFormPage from './GenericCRUDFormPage';

export interface CRUDFormPageProps<T> {
	title: string;
	groups: Array<CRUDFormGroupType | Array<CRUDFormGroupType>>;
	backwardUrl: string;
	createUrl: string;
	endpoint: string;
	onAfterFetch?: (data: T) => object;
	onBeforeSubmit?: (payload: BeforeSubmitType, form: T) => BeforeSubmitType;
	children?: React.ReactNode;
}

function CRUDFormPage<T>({
	title,
	groups,
	backwardUrl,
	createUrl,
	endpoint,
	onAfterFetch,
	onBeforeSubmit,
	children,
}: CRUDFormPageProps<T>) {
	const { form, setForm, handleSave, loading, error } = useCRUDFormPage(
		endpoint,
		groups,
		createUrl,
		onAfterFetch,
		onBeforeSubmit,
	);

	return (
		<GenericCRUDFormPage
			title={title}
			groups={groups}
			backwardUrl={backwardUrl}
			createUrl={createUrl}
			form={form as never}
			setForm={setForm}
			loading={loading}
			handleSave={handleSave}
			error={error}
		>
			{children}
		</GenericCRUDFormPage>
	);
}

export default CRUDFormPage;
