import React from 'react';

interface LogProps {
	logs: string[];
}

const Log: React.FC<LogProps> = ({ logs }) => {
	const getColorForLogLevel = (level: string): string => {
		switch (level) {
			case 'INFO':
				return 'text-blue-500';
			case 'DEBUG':
				return 'text-green-500';
			case 'ERROR':
				return 'text-red-500';
			case 'WARNING':
				return 'text-yellow-500';
			default:
				return 'text-black';
		}
	};

	const parseLogLine = (logLine: string) => {
		console.log(logLine);
		const logParts = logLine.split(':');
		const level = logParts[0].trim();
		const message = logParts.slice(1).join(':').trim();
		return { level, message };
	};

	return (
		<pre className="p-4 bg-gray-100 rounded-md">
			{logs.map((log, index) => {
				const { level, message } = parseLogLine(log);
				return (
					<div key={index} className="whitespace-pre-wrap font-mono">
						<span className={`${getColorForLogLevel(level)} font-bold`}>{level}:</span> {message}
					</div>
				);
			})}
		</pre>
	);
};

export default Log;
