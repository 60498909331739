import { useEffect, useState } from 'react';
import useAPI from './useAPI';
import { useAuth } from '../useAuth';
import { SessionUser } from '../../shared/models/User';

export default function useAPIProfile() {
	const { user } = useAuth();

	const api = useAPI(true, user?.access_token);
	const [sessionUser, setSessionUser] = useState<SessionUser | undefined>(undefined);
	const [loading, setLoading] = useState(false);

	async function fetchProfile() {
		setSessionUser(undefined);
		setLoading(true);

		const profile = await api.get('/profile');
		setSessionUser(profile);
		setLoading(false);
	}

	useEffect(() => {
		fetchProfile();
	}, [user]);

	return { sessionUser, loading, fetchProfile };
}
