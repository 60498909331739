import React, { createContext, useState, ReactNode, useEffect, useCallback } from 'react';
import { useAuth } from '../hooks/useAuth';
import useAPITenants from '../hooks/api/useAPITenants';
import useAPIProfile from '../hooks/api/useAPIProfile';
import { RoleType, SessionUser } from '../shared/models/User';

const TENANT_ROLE_ID_LS_KEY = 'tenantcontext_state_tenant-role-id';

export type ProfileProviderProps = {
	children: ReactNode;
};

export interface TenantRole {
	id: string;
	name: string;
	role: RoleType;
}

export type ProfileContextType = {
	tenant?: TenantRole;
	setTenant: (id: string) => void;
	tenants: TenantRole[];
	sessionUser?: SessionUser;
	saasAdmin: boolean;
	loading: boolean;
};

export const ProfileContext = createContext<ProfileContextType | undefined>(undefined);

export const ProfileProvider: React.FC<ProfileProviderProps> = ({ children }) => {
	const [selectedTenantRoleId, setSelectedTenantRoleId] = useState<string | undefined>(undefined);
	const [tentantRoles, setTenantRoles] = useState<TenantRole[]>([]);

	const { isAuthenticated, user } = useAuth();
	const { tenants, loading: tenantsLoading } = useAPITenants();
	const { sessionUser, loading: profileLoading } = useAPIProfile();

	const loading = tenantsLoading || profileLoading;

	useEffect(() => {
		const storedTenantRoleId = localStorage.getItem(TENANT_ROLE_ID_LS_KEY);
		if (!storedTenantRoleId) return;
		setSelectedTenantRoleId(storedTenantRoleId);
	}, []);

	useEffect(() => {
		if (selectedTenantRoleId) localStorage.setItem(TENANT_ROLE_ID_LS_KEY, selectedTenantRoleId);
	}, [selectedTenantRoleId]);

	const loadTenantRoles = useCallback(() => {
		if (!isAuthenticated) return;
		if (!tenants) return;
		if (!sessionUser) return;

		const customClaimRoles = sessionUser.roles;
		const tenantRoles = Object.keys(customClaimRoles).map((key) => {
			const tenantName = tenants ? tenants.find((tenant) => tenant.id === key)?.name : key;
			return {
				id: key,
				name: tenantName || (loading ? 'Loading tenant...' : key),
				role: customClaimRoles[key as RoleType].name,
			};
		});

		setTenantRoles(tenantRoles);
		if (!selectedTenantRoleId) setSelectedTenantRoleId(tenantRoles[0].id);
	}, [isAuthenticated, tenants, sessionUser, loading]);

	useEffect(() => {
		if (!isAuthenticated || !user) return;
		loadTenantRoles();
	}, [isAuthenticated, user, loadTenantRoles]);

	const tenant = tentantRoles.find((tenant) => tenant.id === selectedTenantRoleId);
	const saasAdmin = !!tenant && tenant.role === 'SAASAdmin';

	return (
		<ProfileContext.Provider
			value={{
				tenant,
				setTenant: setSelectedTenantRoleId,
				tenants: tentantRoles,
				sessionUser,
				saasAdmin,
				loading,
			}}
		>
			{children}
		</ProfileContext.Provider>
	);
};
