import React, { useEffect } from 'react';

import { Condition, OrCondition, QueryOperator } from '../hooks/api/useCRUDQuery';

import Input from '../components/Input';
import Select from '../components/Select';

export interface FieldOperator {
	field: string;
	op: QueryOperator;
}

export interface Or {
	operators: Array<FieldOperator>;
}

export type FieldOperatorResolver = (value?: string) => Array<FieldOperator> | Or | undefined;

export function isOr(field: FieldOperator[] | Or): field is Or {
	return (field as Or).operators !== undefined;
}

export interface Filter {
	id: string;
	label?: string;
	placeholder?: string;
	initialValue?: string;
	value?: string;
	options?: Array<{ key: string; label: string }>;
	operator: FieldOperator | FieldOperatorResolver;
}

export interface FiltersProp {
	filters: Array<Filter>;
	onValueChange: (id: string, value?: string) => void;
	onOperatorChange: (condition: Condition | OrCondition) => void;
}

export default function Filters({ filters, onValueChange, onOperatorChange }: FiltersProp) {
	useEffect(() => {
		if (!filters) return;
		for (const filter of filters) {
			if (filter.initialValue) {
				handleFilterValuesChange(filter, filter.initialValue);
			}
		}
	}, []);

	function handleFilterValuesChange(filter: Filter, value?: string) {
		const fieldOperator = filter.operator as FieldOperator;
		if (fieldOperator.field === undefined) {
			const fieldOperatorResolver = filter.operator as FieldOperatorResolver;
			const resolvedFieldOperators = fieldOperatorResolver(value);
			if (resolvedFieldOperators) {
				onValueChange(filter.id, value);
				if (isOr(resolvedFieldOperators)) {
					onOperatorChange({
						conditions: resolvedFieldOperators.operators.map((op) => ({
							field: op.field,
							op: op.op,
						})),
						value: value,
					} as OrCondition);
				} else {
					for (const resolvedFieldOperator of resolvedFieldOperators) {
						const { field, op } = resolvedFieldOperator;
						onOperatorChange({ operator: { field, op }, value });
					}
				}
			}
		} else {
			onValueChange(filter.id, value);
			onOperatorChange({ operator: { field: fieldOperator.field, op: fieldOperator.op }, value });
		}
	}

	return (
		<div className="flex flex-col gap-4">
			<div className="flex flex-row gap-2">
				{filters.map((filter) => (
					<div key={filter.id} className="w-64">
						{(filter.options && (
							<Select
								condensed
								rounded
								disposition="row"
								label={filter.label}
								placeholder={filter.placeholder}
								options={filter.options}
								value={filter.value}
								onChange={(value) => handleFilterValuesChange(filter, value)}
							/>
						)) || (
							<Input
								label={filter.label}
								placeholder={filter.placeholder}
								value={filter.value}
								onChange={(value) => handleFilterValuesChange(filter, value)}
								disposition="row"
								condensed
								rounded
								contentIcon={{
									type: 'UilMultiply',
									color: 'text-red-600',
									action: () => handleFilterValuesChange(filter),
								}}
								noContentIcon={{
									type: 'UilSearch',
									color: 'text-gray-400',
								}}
							/>
						)}
					</div>
				))}
			</div>
		</div>
	);
}
