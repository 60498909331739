import { Breadcrumb } from '../types/Breadcrumb';

export const sitemap: Array<Breadcrumb> = [
	{
		path: 'isp',
		title: 'ISP',
		disabled: false,
		items: [
			{
				deadend: true,
				title: 'Form',
			},
		],
	},
	{
		path: 'isp-import',
		title: 'ISP Import Tool',
		disabled: false,
		items: [
			{
				deadend: true,
				title: 'Form',
			},
		],
	},
	{
		path: 'users',
		title: 'Users',
		disabled: false,
		items: [
			{
				deadend: true,
				title: 'Form',
			},
		],
	},
	{
		path: 'service',
		title: 'Management',
		disabled: true,
		items: [
			{
				path: 'types',
				title: 'Types',
				items: [
					{
						deadend: true,
						title: 'Form',
					},
				],
			},
			{
				path: 'speeds',
				title: 'Speeds',
				items: [
					{
						deadend: true,
						title: 'Form',
					},
				],
			},
			{
				path: 'plans',
				title: 'Plans',
				items: [
					{
						deadend: true,
						title: 'Form',
					},
				],
			},
		],
	},
	{
		path: 'settings',
		title: 'Settings',
	},
	{
		path: 'help',
		title: 'Help',
	},
	{
		path: 'infra',
		title: 'Infrastructure',
		disabled: true,
		items: [
			{
				path: 'nas',
				title: 'NAS',
				items: [
					{
						deadend: true,
						title: 'Form',
					},
				],
			},
		],
	},
	{
		path: 'customers',
		title: 'Customers',
		disabled: false,
		items: [
			{
				deadend: true,
				title: 'Form',
			},
		],
	},
	{
		path: 'subscriptions',
		title: 'Subscriptions',
		disabled: false,
		items: [
			{
				deadend: true,
				title: 'Form',
			},
		],
	},
];
