import { useCallback, useState } from 'react';
import useAPI from './useAPI';
import { useProfile } from '../useProfile';
import { APIError } from '../../shared/api_errors';
import { useAuth } from '../useAuth';

function handleError(setError: (error: APIError) => void, error: unknown) {
	if (typeof error === 'string') {
		setError(new APIError(error));
	} else if (error instanceof Error) {
		setError(new APIError(error.message));
	} else if (error instanceof APIError) {
		if (error.validations_error && error.validations_error.length > 0) {
			const detailedMessage = error.validations_error.map((ve) => `${ve.loc[1]}: ${ve.msg}`).join('\n');
			setError(new APIError(detailedMessage, error.status_code, error.validations_error));
		} else {
			setError(error);
		}
	} else {
		setError(new APIError('An unknown error occurred.'));
	}
}

function useCRUDOperations(endpoint: string) {
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<APIError | undefined>();

	const { tenant } = useProfile();
	const { user } = useAuth();
	const api = useAPI(true, user?.access_token, tenant);

	const get = useCallback(
		async (id: string) => {
			try {
				setLoading(true);
				const result = await api.get(`${endpoint}/${id}`);
				setError(undefined);
				return result;
			} catch (error) {
				handleError(setError, error);
			} finally {
				setLoading(false);
			}
		},
		[endpoint, api.get],
	);

	// Function to add a new item
	const add = useCallback(
		async (data: unknown) => {
			try {
				setLoading(true);
				const result = await api.post(endpoint, {
					body: data,
				});
				setError(undefined);
				return result;
			} catch (error) {
				handleError(setError, error);
			} finally {
				setLoading(false);
			}
		},
		[endpoint, api.post],
	);

	// Function to update an existing item by id
	const update = useCallback(
		async (id: string, data: unknown) => {
			try {
				setLoading(true);
				const result = await api.put(`${endpoint}/${id}`, {
					body: data,
				});
				setError(undefined);
				return result;
			} catch (error) {
				handleError(setError, error);
			} finally {
				setLoading(false);
			}
		},
		[endpoint, api.put],
	);

	// Function to delete an item by id
	const remove = useCallback(
		async (id: string) => {
			try {
				setLoading(true);
				await api.del(`${endpoint}/${id}`);
				setError(undefined);
			} catch (error) {
				handleError(setError, error);
			} finally {
				setLoading(false);
			}
		},
		[endpoint, api.del],
	);

	// Expose the generic API manipulation functions
	return { get, add, update, remove, loading, error, ready: !!tenant };
}

export default useCRUDOperations;
