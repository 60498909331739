import React, { useState } from 'react';
import Overlay from '../components/Overlay';
import Button, { ButtonProps } from '../components/Button';
import Input from '../components/Input';

interface ConfimationBoxProps {
	title: string;
	message?: string;
	inverted?: boolean;
	ok?: string;
	cancel?: string;
	confirmationText?: string;
	confirmationPlaceholder?: string;
	onOk?: () => void;
	onCancel?: () => void;
}

export default function ConfimationBox({
	title,
	message,
	inverted,
	ok = 'Confirm',
	cancel = 'Abort',
	confirmationText,
	onOk,
	onCancel,
}: ConfimationBoxProps) {
	const [inputText, setInputText] = useState('');

	const okButton: ButtonProps = inverted
		? { text: ok, variant: 'danger', style: 'outline' }
		: { text: ok, variant: 'success' };
	const cancelButton: ButtonProps = inverted
		? { text: cancel, variant: 'success' }
		: { text: cancel, variant: 'danger', style: 'outline' };

	const handleOkClick = () => {
		if (!confirmationText || inputText === confirmationText) {
			onOk?.();
		}
	};

	return (
		<Overlay>
			<div className="flex flex-col items-center gap-2">
				<h2 className="font-bold text-lg">{title}</h2>
				<p>
					{message} Type <span className="font-bold">{confirmationText}</span> to confirm!
				</p>
				{confirmationText && (
					<div className="w-full">
						<Input
							type="text"
							placeholder={`Type ${confirmationText} to confirm`}
							value={inputText}
							onChange={(value) => setInputText(value)}
						/>
					</div>
				)}
				<div className="mt-4 flex gap-2">
					<div className="w-32">
						<Button block {...okButton} onClick={handleOkClick} />
					</div>
					<div className="w-32">
						<Button block {...cancelButton} onClick={onCancel} />
					</div>
				</div>
			</div>
		</Overlay>
	);
}
