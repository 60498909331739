import { CRUDObj } from '../../pages/GenericCRUDPage';
import { ISP } from './ISP';

export type RoleType = 'SAASAdmin' | 'ISPAdmin' | 'ISPOperator';
export const roleTypes: RoleType[] = ['SAASAdmin', 'ISPAdmin', 'ISPOperator'] as RoleType[];

export interface User extends CRUDObj {
	id: string;
	email: string;
	saas_admin: boolean;
	family_name: string;
	given_name: string;
	enabled: boolean;
	isps?: Array<{
		isp_id: string;
		isp: ISP;
		role: string;
	}>;
}

export interface SessionUser {
	id: string;
	email: string;
	email_verified: boolean;
	family_name: string;
	given_name: string;
	sub: string;
	roles: {
		[key: string]: {
			name: RoleType;
			description: string;
			permissions?: Array<string>;
		};
	};
}
