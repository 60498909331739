import { useEffect, useState } from 'react';
import { Location, useLocation } from 'react-router-dom';

import { MenuItem } from '../types/MenuItem';
import { useProfile } from './useProfile';
import { TenantRole } from '../context/ProfileContext';

function createMenu(tenant?: TenantRole): MenuItem[] {
	const menu = Array<MenuItem>();
	menu.push({
		id: 'home',
		title: 'Dashboard',
		icon: 'UilEstate',
		to: '/',
	});

	if (tenant?.role === 'SAASAdmin') {
		menu.push(
			{
				id: 'services',
				title: 'SaaS',
				separator: true,
			},
			{
				id: 'saas-data',
				title: 'Management',
				icon: 'UilConstructor',
				items: [
					{ id: 'isp-import', title: 'ISP Import', to: 'isp-import' },
					{ id: 'isp', title: 'ISP', to: 'isp' },
					{ id: 'users', title: 'Users', to: 'users' },
				],
			},
		);
	}

	if (tenant?.role === 'ISPAdmin') {
		menu.push(
			{
				id: 'services',
				title: 'ISP',
				separator: true,
			},
			{
				id: 'management',
				title: 'Management',
				icon: 'UilDatabase',
				items: [
					{ id: 'service-type', title: 'Types', to: '/service/types' },
					{ id: 'service-speed', title: 'Speeds', to: '/service/speeds' },
					{ id: 'service-plan', title: 'Plans', to: '/service/plans' },
					{ id: 'ispsettings', title: 'Settings', to: '/settings' },
					{ id: 'users', title: 'Users', to: 'users' },
				],
			},
			{
				id: 'infra',
				title: 'Infrastructure',
				icon: 'UilWifiRouter',
				items: [{ id: 'service-nas', title: 'NAS', to: '/infra/nas' }],
			},
			{ id: 'customers', title: 'Customers', to: '/customers', icon: 'UilUsersAlt' },
			{ id: 'subscriptions', title: 'Subscriptions', to: '/subscriptions', icon: 'UilBill' },
		);
	}

	return menu;
}

function setCurrentPathname(menu: MenuItem[], location: Location) {
	// Change the menu items applying the current flag to sinilize it's the current url opened "location.pathname"
	for (const item of menu) {
		if (item.to) {
			if (item.to !== '/') {
				item.current = !!item.to && location.pathname.indexOf(item.to) > -1;
			} else {
				item.current = location.pathname === '/';
			}
		}

		if (!item.items) continue;
		for (const subItem of item.items) {
			item.current = subItem.current = !!subItem.to && location.pathname.indexOf(subItem.to) > -1;
			if (item.current) break;
		}
	}

	return menu;
}

function useMenu() {
	const location = useLocation();
	const { tenant } = useProfile();
	const [menu, setMenu] = useState<MenuItem[]>([]);

	useEffect(() => {
		const createdMenu = createMenu(tenant);
		const currentPathMenu = setCurrentPathname(createdMenu, location);

		setMenu(currentPathMenu);
	}, [location, tenant]);

	return { items: menu };
}

export default useMenu;
