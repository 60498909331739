if (!process.env.REACT_APP_OIDC_ISSUER) throw Error('No REACT_APP_OIDC_ISSUER on env.');
if (!process.env.REACT_APP_OIDC_DOMAIN) throw Error('No REACT_APP_OIDC_DOMAIN on env.');
if (!process.env.REACT_APP_OIDC_CLIENT) throw Error('No REACT_APP_OIDC_CLIENT on env.');

const config = {
	oidc: {
		issuer: process.env.REACT_APP_OIDC_ISSUER,
		domain: process.env.REACT_APP_OIDC_DOMAIN,
		clientId: process.env.REACT_APP_OIDC_CLIENT,
	},
	api: {
		url: process.env.REACT_APP_API_URL || 'http://localhost:5100',
	},
};

export default config;
