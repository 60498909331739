import React, { ReactNode } from 'react';

interface StickyBottomProps {
	children: ReactNode;
}

export default function StickyBottom({ children }: StickyBottomProps) {
	return (
		<div className="sticky bottom-0 flex items-center h-24 gap-2 p-4 bg-white shadow-[0_-4px_6px_-1px_rgba(0,0,0,0.1)]">
			{children}
		</div>
	);
}
