import { useEffect, useState } from 'react';
import useAPI from './useAPI';

export default function useAPIVersion() {
	const api = useAPI();
	const [version, setVersion] = useState<string>();
	const [loading, setLoading] = useState(false);

	async function fetchVersion() {
		setVersion(undefined);
		setLoading(true);

		const { version } = await api.get('/version');
		setVersion(version);
		setLoading(false);
	}

	useEffect(() => {
		fetchVersion();
	}, []);

	return { version, loading, fetchVersion };
}
