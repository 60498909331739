import { useLocation } from 'react-router-dom';

import { Breadcrumb } from '../types/Breadcrumb';
import { sitemap } from '../constants/sitemap';

function useBreadcrumbs() {
	const location = useLocation();
	const pathnames = location.pathname.split('/').filter((path: string) => path);

	function build(sitemap: Array<Breadcrumb>, breadcrumbs: Array<Breadcrumb> = []) {
		if (!pathnames[breadcrumbs.length]) return breadcrumbs;

		const breadcrumb = sitemap.find((bc) => bc.path === pathnames[breadcrumbs.length] || bc.deadend);
		if (breadcrumb) {
			breadcrumbs.push(breadcrumb);
			breadcrumb.fullPath = `/${pathnames.slice(0, breadcrumbs.length).join('/')}`;
			breadcrumb.items && build(breadcrumb.items, breadcrumbs);
			return breadcrumbs;
		}

		return breadcrumbs;
	}

	const crumbs = build(sitemap);

	return { pathnames, crumbs };
}

export default useBreadcrumbs;
