import { useEffect, useState } from 'react';
import { Subscription } from '../../shared/models/Subscription';
import useAPINAS from '../api/useAPINAS';
import { generateCreateGroups } from '../../pages/util/generateCreateGroups';

export function useCreateSubscriptionGroups(form: Subscription, setForm: (form: Subscription) => void) {
	const { pools } = useAPINAS(form.infra_nas_pool?.infra_nas?.id || null, { mustFetchPools: true });
	const [groups, setGroups] = useState(generateCreateGroups(form, setForm));

	useEffect(() => {
		setGroups(generateCreateGroups(form, setForm, pools));
	}, [form, setForm, pools]);

	return { groups };
}
