import { useEffect, useState } from 'react';
import useAPI from './useAPI';
import { ISPSettings, ISPSettingsPayload } from '../../shared/models/ISPSettings';
import { useProfile } from '../useProfile';
import { APIError } from '../../shared/api_errors';
import { useAuth } from '../useAuth';

export default function useAPIISPSettings() {
	const { tenant } = useProfile();
	const { user } = useAuth();
	const api = useAPI(true, user?.access_token, tenant);

	const [settings, setSettings] = useState<ISPSettings | undefined>(undefined);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<APIError | undefined>(undefined);

	async function fetchSettings() {
		if (!tenant) {
			setLoading(false);
			return;
		}

		setSettings(undefined);
		setLoading(true);

		try {
			const settings = await api.get('/isp/settings');
			setError(undefined);
			setLoading(false);
			setSettings(settings);
		} catch (error) {
			if (typeof error === 'string') {
				setError(new APIError(error));
			} else if (error instanceof Error) {
				setError(new APIError(error.message));
			} else if (error instanceof APIError) {
				setError(error);
			}
		} finally {
			setLoading(false);
		}
	}

	async function save(payload: ISPSettingsPayload) {
		if (!tenant) {
			setLoading(false);
			return;
		}

		setLoading(true);

		try {
			const result = await api.post('/isp/settings', { body: payload });
			setSettings(result);
			setError(undefined);
			setLoading(false);
			return result;
		} catch (error) {
			if (typeof error === 'string') {
				setError(new APIError(error));
			} else if (error instanceof Error) {
				setError(new APIError(error.message));
			} else if (error instanceof APIError) {
				setError(error);
			}
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		fetchSettings();
	}, [tenant]);

	return { settings, loading, fetchSettings, error, save };
}
