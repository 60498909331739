import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import UilIcon from '../components/UilIcon';

import useMenu from '../hooks/useMenu';
import Versions from './Versions';

export interface SidebarProps {
	open: boolean;
	setOpen: (open: boolean) => void;
	fold: boolean;
}

function Sidebar({ open, setOpen, fold }: SidebarProps) {
	const navigate = useNavigate();

	// Classes para controlar a visibilidade e a posição em telas pequenas e médias
	const mobileClasses = open ? 'translate-x-0' : '-translate-x-full';

	// Classes para telas grandes onde a barra lateral sempre estará visível
	const desktopClasses = 'lg:translate-x-0 lg:fixed flex';

	const foldingClasses = !fold ? (open ? 'w-72' : 'w-72') : !open ? 'w-20' : 'w-20 max-lg:w-72';

	const sidebarRef = useRef<HTMLInputElement>(null);
	useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
				setOpen(false);
			}
		}

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [sidebarRef, setOpen]);

	return (
		<aside
			ref={sidebarRef}
			className={`z-[1000] fixed top-0 start-0 left-0 h-screen bg-white shadow-md transform transition-transform duration-300 ease-in-out flex flex-col ${mobileClasses} ${desktopClasses} ${foldingClasses}`}
		>
			<div className="w-full h-16 px-6 flex items-center justify-center">
				<button className="h-12" onClick={() => navigate('/')}>
					<img className={`${fold ? 'lg:hidden' : ''} h-12`} src="/imgs/logo-dark.png" alt="logo" />
					<img
						className={`${!fold ? 'hidden' : ''} h-8 max-lg:hidden`}
						src="/imgs/logo-fold.png"
						alt="logo"
					/>
				</button>
			</div>
			<nav className="relative w-full h-full overflow-scroll flex flex-col justify-between">
				<div className={`${!fold ? 'hidden' : 'max-lg:hidden'}`}>
					<FoldMenu />
				</div>
				<div className={`${fold ? 'lg:hidden' : ''}`}>
					<FullMenu />
				</div>
				<Versions fold={fold} />
			</nav>
		</aside>
	);
}

function FoldMenu() {
	const navigate = useNavigate();
	const { items } = useMenu();
	const [selected, setSelected] = useState('');

	function handleEnter(id: string) {
		setSelected(id);
	}

	function handleLeave() {
		setSelected('');
	}

	const elements = items
		.filter((item) => !item.separator)
		.filter((item) => !item.hidden)
		.map((item) => (
			<li
				key={item.id}
				className="px-4"
				onMouseEnter={() => handleEnter(item.id)}
				onMouseLeave={() => handleLeave()}
			>
				<button
					className={`rounded-lg h-12 w-full overflow-hidden cursor-pointer flex items-center justify-center outline-none text-gray-400 ${item.current && 'bg-primary-50 text-primary'} hover:bg-primary-50 hover:text-primary hover:outline-none active:text-primary active:outline-none`}
					onClick={() => item.to && navigate(item.to)}
				>
					{item.icon && <UilIcon type={item.icon} />}
				</button>
				{item.items && (
					<ul
						className={`fixed w-56 -mt-12 ml-14 bg-white shadow-lg rounded-r-md ${selected !== item.id && 'hidden'}`}
					>
						{item.items.map((subItem) => (
							<li key={`${item.id}-${subItem.id}`}>
								<button
									className="rounded-l-full h-10 w-full pl-6 cursor-pointer flex items-center justify-between gap-4 outline-none text-gray-400 hover:bg-primary-50 hover:text-primary hover:outline-none active:text-primary active:outline-none"
									onClick={() => subItem.to && navigate(subItem.to)}
								>
									<span className={`${subItem.current ? 'text-primary' : 'text-gray'} text-sm`}>
										{subItem.title}
									</span>
								</button>
							</li>
						))}
					</ul>
				)}
			</li>
		));

	return <ul className="m-0 list-none">{elements}</ul>;
}

function FullMenu() {
	const navigate = useNavigate();

	const { items } = useMenu();
	const [expanded, setExpanded] = useState<Array<string>>([]);

	function handleToggle(item: string) {
		setExpanded(expanded.includes(item) ? expanded.filter((element) => element !== item) : [...expanded, item]);
	}

	useEffect(() => {
		setExpanded(items.filter((item) => item.current).map((item) => item.id));
	}, [items]);

	const elements = items
		.filter((item) => !item.hidden)
		.map((item) => (
			<li key={item.id}>
				{(!item.separator && (
					<>
						<button
							className="rounded-r-full h-12 w-full px-6 cursor-pointer flex items-center justify-between gap-4 outline-none text-gray-400 group hover:bg-primary-50 hover:text-primary hover:outline-none active:text-primary active:outline-none"
							onClick={() => (item.to && navigate(item.to)) || handleToggle(item.id)}
						>
							<div
								className={`flex items-center gap-4 ${item.current ? 'text-primary' : 'text-gray-900'} group`}
							>
								{item.icon && (
									<UilIcon
										type={item.icon}
										className="w-5 h-5 text-gray-400 group-hover:text-primary"
									/>
								)}
								<span
									className={`${item.current ? 'text-primary' : 'text-gray'} text-sm font-bold group-hover:text-primary`}
								>
									{item.title}
								</span>
							</div>
							{item.items && (
								<FontAwesomeIcon icon={faChevronDown} className="text-xs group-hover:text-primary" />
							)}
						</button>
						{item.items && (
							<ul
								className={`list-none m-0 p-0 mr-8 scrollbar ${expanded.indexOf(item.id) === -1 && 'hidden'}`}
							>
								{item.items.map((subItem) => (
									<li key={`${item.id}-${subItem.id}`}>
										<button
											className="rounded-r-full h-10 w-full pl-16 cursor-pointer flex items-center justify-between gap-4 outline-none text-gray-400 hover:bg-primary-50 hover:text-primary hover:outline-none active:text-primary active:outline-none"
											onClick={() => subItem.to && navigate(subItem.to)}
										>
											<span
												className={`${subItem.current ? 'text-primary' : 'text-gray-800'} text-sm`}
											>
												{subItem.title}
											</span>
										</button>
									</li>
								))}
							</ul>
						)}
					</>
				)) || (
					<div className="px-6 mt-6 mb-2">
						<span className="font-bold text-xs text-gray-500 uppercase">{item.title}</span>
					</div>
				)}
			</li>
		));

	return <ul className="m-0 list-none">{elements}</ul>;
}

export default Sidebar;
