import React from 'react';
import { IconType } from '@iconscout/react-unicons';

import UilIcon from '../components/UilIcon';

const colors = {
	info: 'bg-indigo-100 text-indigo-600 border-indigo-200',
	danger: 'bg-red-200 text-red-600 border-red-300',
	success: 'bg-green-100 text-green-600 border-green-200',
	warning: 'bg-amber-100 text-amber-600 border-amber-200',
};

const icons: { [key: string]: IconType } = {
	info: 'Uil0Plus',
	danger: 'UilExclamationOctagon',
	success: 'UilCheckCircle',
	warning: 'UilExclamationTriangle',
};

interface AlertProps {
	type: 'info' | 'danger' | 'success' | 'warning';
	title: string;
	message?: string;
}

export default function Alert({ type, title, message }: AlertProps) {
	return (
		<div className={`px-5 py-2 rounded-lg border-[0.5px] ${colors[type]}`}>
			<div className="flex items-center flex-wrap gap-2">
				<span className="self-stretch">
					<UilIcon type={icons[type]} size={18} />
				</span>
				<div>
					<h6 className={`font-bold text-sm ${message && 'mb-2'}`}>{title}</h6>
					<span className="font-normal text-sm">{message}</span>
				</div>
			</div>
		</div>
	);
}
