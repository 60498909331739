import React from 'react';

import { DataTableField } from '../../components/DataTable';
import { Filter } from '../../slices/Filters';

import CRUDPage from '../CRUDPage';
import { NAS } from '../../shared/models/infra/NAS';

const fields: Array<DataTableField<NAS>> = [
	{ title: 'Name', property: 'name' },
	{ title: 'Identity', property: 'identifier' },
	{ title: 'Radius Src. Address', property: 'ip' },
	{ title: 'IP', property: 'api_url' },
	{ title: 'Username', property: 'username' },
	{ title: 'Pools', property: 'pools', extractor: (row) => row.pools.map((item) => item.name).join(', ') },
];
const endpoint = '/infra/nas';
const formUrl = '/infra/nas/new';

export default function NASPage() {
	const filters: Array<Filter> = [
		{
			id: 'name',
			label: 'Name:',
			operator: {
				field: 'name',
				op: 'ilike',
			},
		},
		{
			id: 'ip',
			label: 'IP:',
			operator: {
				field: 'ip',
				op: 'ilike',
			},
		},
	];

	return (
		<CRUDPage
			title="NAS"
			endpoint={endpoint}
			initialSortField="name"
			fields={fields}
			filters={filters}
			formUrl={formUrl}
			useDefaultActions
		/>
	);
}
