import React from 'react';
import { Outlet, Route, createBrowserRouter, createRoutesFromElements, json } from 'react-router-dom';

import Full from '../layouts/Full';
import Centered from '../layouts/Centered';

import HomePage from '../pages/HomePage';
import TokenPage from '../pages/TokenPage';
import ButtonsPage from '../pages/ButtonsPage';
import BlankPage from '../pages/BlankPage';

import CallbackPage from '../pages/CallbackPage';
import LoginPage from '../pages/LoginPage';
import LogoutPage from '../pages/LogoutPage';
import ExitDoorPage from '../pages/ExitDoorPage';

import AuthorizedRoute from './AuthorizedRoute';
import ErrorPage from '../pages/ErrorPage';

import ISPSettingsPage from '../pages/ISPSettingsPage';
import ISPPage from '../pages/ISPPage';
import ISPByIdPage from '../pages/ISPByIdPage';
import UserPage from '../pages/UserPage';
import UserByIdPage from '../pages/UserByIdPage';
import ISPImportPage from '../pages/ISPImportPage';
import ISPImportTxtPage from '../pages/ISPImportTxtPage';
import HelpPage from '../pages/HelpPage';

import ServiceTypePage from '../pages/service/TypePage';
import ServiceTypeByIdPage from '../pages/service/TypeByIdPage';
import ServiceSpeedPage from '../pages/service/SpeedPage';
import ServiceSpeedByIdPage from '../pages/service/SpeedByIdPage';
import ServicePlanPage from '../pages/service/PlanPage';
import ServicePlanByIdPage from '../pages/service/PlanByIdPage';

import InfraNASPage from '../pages/infra/NASPage';
import InfraNASByIdPage from '../pages/infra/NASByIdPage';

import ClientPage from '../pages/ClientPage';
import ClientByIdPage from '../pages/ClientByIdPage';
import SubscriptionPage from '../pages/SubscriptionPage';
import SubscriptionByIdPage from '../pages/SubscriptionByIdPage';

const FullLayout = () => (
	<Full>
		<Outlet />
	</Full>
);
const CenteredLayout = () => (
	<Centered>
		<Outlet />
	</Centered>
);

const notFoundLoader = () => {
	throw json({}, { status: 404 });
};

export const routes = createRoutesFromElements(
	<Route
		loader={async (p) => {
			console.log('route', p.request.url);
			return null;
		}}
	>
		<Route path="/auth" element={<CenteredLayout />}>
			<Route errorElement={<ErrorPage />}>
				<Route path="/auth/callback" element={<CallbackPage />} />
				<Route path="/auth/login" element={<LoginPage />} />
				<Route path="/auth/logout" element={<LogoutPage />} />
				<Route path="/auth/exitdoor" element={<ExitDoorPage />} />
				<Route path="*" loader={notFoundLoader} />
			</Route>
		</Route>
		<Route element={<AuthorizedRoute />}>
			<Route path="/" element={<FullLayout />}>
				<Route errorElement={<ErrorPage />}>
					<Route index element={<HomePage />} />
					<Route path="token" element={<TokenPage />} />
					<Route path="help" element={<HelpPage />} />
					<Route path="settings" element={<ISPSettingsPage />} />
					<Route path="isp" element={<ISPPage />} />
					<Route path="isp/:id" element={<ISPByIdPage />} />
					<Route path="users" element={<UserPage />} />
					<Route path="users/:id" element={<UserByIdPage />} />
					<Route path="isp-import" element={<ISPImportPage />} />
					<Route path="isp-import/:isp_id/:nas_id/:currency_id" element={<ISPImportTxtPage />} />
					<Route path="/customers" element={<ClientPage />} />
					<Route path="/customers/:id" element={<ClientByIdPage />} />
					<Route path="/subscriptions" element={<SubscriptionPage />} />
					<Route path="/subscriptions/:id" element={<SubscriptionByIdPage />} />
					<Route path="blank" element={<BlankPage />} />
					<Route path="buttons" element={<ButtonsPage />} />
					<Route path="*" loader={notFoundLoader} />
				</Route>
				<Route path="/service">
					<Route path="/service/types" element={<ServiceTypePage />} />
					<Route path="/service/types/:id" element={<ServiceTypeByIdPage />} />
					<Route path="/service/speeds" element={<ServiceSpeedPage />} />
					<Route path="/service/speeds/:id" element={<ServiceSpeedByIdPage />} />
					<Route path="/service/plans" element={<ServicePlanPage />} />
					<Route path="/service/plans/:id" element={<ServicePlanByIdPage />} />
				</Route>
				<Route path="/infra">
					<Route path="/infra/nas" element={<InfraNASPage />} />
					<Route path="/infra/nas/:id" element={<InfraNASByIdPage />} />
				</Route>
			</Route>
		</Route>
	</Route>,
);

const router = createBrowserRouter(routes);

export default router;
