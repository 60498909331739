import React, { useEffect, useState } from 'react';

import { CRUDFormGroupType } from '../components/CRUDFormGroup';
import { Subscription, SubscriptionStatus, getSubscriptionStatus } from '../shared/models/Subscription';
import { applyMask } from '../components/Input';
import { BeforeSubmitType, useCRUDFormPage } from '../hooks/useCRUDForm';
import GenericCRUDFormPage from './GenericCRUDFormPage';
import { useCreateSubscriptionGroups } from '../hooks/subscription/useCreateSubscriptionGroups';
import { readOnlyGroups } from '../constants/readOnlyGroups';
import { BottomControlCustomAction } from '../slices/BottomControl';
import useAPISubscription from '../hooks/api/useAPISubscription';
import { ButtonIcon } from '../components/Button';
import ConfimationBox from '../slices/ConfirmationBox';

const createUrl = '/subscriptions/new';
const endpoint = '/service/subscriptions';

function handleAfterFetch(data: Subscription): object {
	const form = { ...data, price: '' };
	form.service_plan_price = `${data.service_plan_currency.symbol || ''} ${applyMask((data.service_plan_price as number).toString())}`;

	return form;
}

function handleBeforeSubmit(payload: BeforeSubmitType, form: Subscription) {
	const changedPayload: BeforeSubmitType = {};
	if (!form.id) {
		changedPayload.due_date = form.due_date;
		changedPayload.client_id = form.client_id;
		changedPayload.plan_id = form.service_plan_id;
		changedPayload.pool_id = form.infra_nas_pool_id;
	}

	changedPayload.connectivity_user = form.connectivity_user;
	changedPayload.connectivity_password = form.connectivity_password;
	changedPayload.connectivity_ip = form.connectivity_ip;

	return changedPayload;
}

const PLAY_ICON: ButtonIcon = {
	type: 'UilPlay',
};

const PAUSE_ICON: ButtonIcon = {
	type: 'UilPause',
};

const RESUME_ICON: ButtonIcon = {
	type: 'UilForward',
};

const STOP_ICON: ButtonIcon = {
	type: 'UilSquareFull',
};

function SubscriptionByIdPage() {
	const [groups, setGroups] = useState<CRUDFormGroupType[]>([]);
	const [confirmTerminate, setConfirmTerminate] = useState(false);

	const { id, form, setForm, handleSave, error, loading } = useCRUDFormPage(
		endpoint,
		groups,
		createUrl,
		handleAfterFetch,
		handleBeforeSubmit,
	);
	const {
		activate,
		terminate,
		toggleHold,
		loading: subscriptionLoading,
		error: subscriptionError,
	} = useAPISubscription();

	const subscription = form as Subscription;
	const { groups: createGroups } = useCreateSubscriptionGroups(subscription, setForm);

	useEffect(() => {
		setGroups(id === 'new' ? createGroups : readOnlyGroups);
	}, [id, createGroups]);

	async function handleActivate() {
		if (!id || id === 'new') return;
		const result = await activate(id);
		if (result) setForm(result);
	}

	async function handleToggleHold() {
		if (!id || id === 'new') return;
		const result = await toggleHold(id);
		if (result) setForm(result);
	}

	async function handleTerminate() {
		setConfirmTerminate(true);
	}

	async function handleTerminateOk() {
		if (!id || id === 'new') return;
		const result = await terminate(id);
		if (result) setForm(result);
		setConfirmTerminate(false);
	}

	function getCustomAction(subscription: Subscription) {
		const status = getSubscriptionStatus(subscription);
		const loadingIcon: ButtonIcon | undefined = subscriptionLoading
			? {
					type: 'UilSpinner',
					spin: true,
				}
			: undefined;
		const actions: {
			[K in SubscriptionStatus]: BottomControlCustomAction | undefined;
		} = {
			['Pending']: {
				title: 'Activate',
				variant: 'success',
				action: handleActivate,
				icon: loadingIcon ? loadingIcon : PLAY_ICON,
			},
			['Active']: {
				title: 'Terminate',
				variant: 'danger',
				action: handleTerminate,
				icon: loadingIcon ? loadingIcon : STOP_ICON,
			},
			['Terminated']: undefined,
		};

		const holdAction: BottomControlCustomAction | undefined =
			['Active', 'Terminated'].indexOf(status) > -1
				? {
						title: subscription.held_at ? 'Resume' : 'Suspend',
						variant: subscription.held_at ? 'success' : 'warning',
						action: handleToggleHold,
						icon: loadingIcon ? loadingIcon : subscription.held_at ? RESUME_ICON : PAUSE_ICON,
					}
				: undefined;

		const action = actions[status];
		if (!action) return;

		const result = [];
		if (holdAction) {
			result.push(holdAction);
		}
		result.push(action);

		return result;
	}

	return (
		<>
			<GenericCRUDFormPage
				title="Subscription"
				groups={groups}
				backwardUrl="/subscriptions"
				createUrl={createUrl}
				form={form as never}
				setForm={setForm}
				loading={loading}
				handleSave={handleSave}
				error={error || subscriptionError}
				customActions={getCustomAction(subscription)}
			/>
			{confirmTerminate && (
				<ConfimationBox
					title="Action Confirmation"
					message="Are you sure you want to terminate this subscription? You may not be allowed to rollback!"
					onOk={handleTerminateOk}
					onCancel={() => setConfirmTerminate(false)}
					inverted
				/>
			)}
		</>
	);
}

export default SubscriptionByIdPage;
