import React, { ReactNode, useState } from 'react';

import ConfimationBox from '../slices/ConfirmationBox';
import Filters, { Filter } from '../slices/Filters';
import Button from '../components/Button';
import WhiteBox from '../components/WhiteBox';
import DataTable, { DataTableAction, DataTableField } from '../components/DataTable';
import { APIError } from '../shared/api_errors';
import { Condition, OrCondition, SortOrder } from '../hooks/api/useCRUDQuery';
import { useNavigate } from 'react-router-dom';
import WrapperPage from './WrapperPage';

export interface CRUDObj {
	id: string;
}

export interface GenericCRUDPageProps<T> {
	title: string;
	filters?: Array<Filter>;
	data: T[];
	fields: Array<DataTableField<T>>;
	actions?: DataTableAction<T>[];
	error?: APIError;
	loading: boolean;
	page: number;
	pageSize: number;
	totalRecords: number;
	sortField?: string;
	sortOrder: SortOrder;
	formUrl: string;
	useDefaultActions?: boolean;
	onPageSize: (pageSize: number) => void;
	onPage: (page: number) => void;
	onRefresh?: () => void;
	onSort: (newSortField: string, newSortOrder: SortOrder) => void;
	onValueChange: (id: string, value?: string) => void;
	onOperatorChange: (condition: Condition | OrCondition) => void;
	onRemove?: (id: string) => void;
	children?: ReactNode;
}

const GenericCRUDPage = <T extends CRUDObj>({
	title,
	filters,
	data,
	fields,
	actions,
	error,
	loading,
	page,
	pageSize,
	totalRecords,
	sortField,
	sortOrder,
	formUrl,
	useDefaultActions,
	onPageSize,
	onPage,
	onRefresh,
	onSort,
	onValueChange,
	onOperatorChange,
	onRemove,
	children,
}: GenericCRUDPageProps<T>) => {
	const navigate = useNavigate();
	const [removeId, setRemoveId] = useState<string | undefined>();

	if (useDefaultActions) {
		actions = !actions ? new Array<DataTableAction<T>>() : [...actions];
		actions.push({
			title: 'Editar',
			icon: 'UilEdit',
			action: (row) => navigate(formUrl.replace('/new', `/${row.id}`)),
		});
		actions.push({
			title: 'Remover',
			icon: 'UilTrashAlt',
			action: async (row) => {
				setRemoveId(row.id);
			},
		});
	}

	function handleNew() {
		navigate(formUrl);
	}

	async function handleOkRemove() {
		if (!removeId) return;
		onRemove && (await onRemove(removeId));
		setRemoveId(undefined);
	}

	function handleCancelRemove() {
		setRemoveId(undefined);
	}

	return (
		<WrapperPage title={title} error={error}>
			<div className="mx-4 flex flex-col gap-4">
				<div className="flex items-center justify-between">
					{filters && (
						<Filters filters={filters} onValueChange={onValueChange} onOperatorChange={onOperatorChange} />
					)}
					<Button icon="UilPlus" style="roundedOutline" onClick={handleNew} />
				</div>
				<WhiteBox>
					<DataTable
						fields={fields}
						actions={actions}
						rows={data}
						pages={{ page, size: pageSize, total: totalRecords }}
						onPageSize={onPageSize}
						onPage={onPage}
						onRefresh={onRefresh}
						loading={loading}
						sort={sortField ? { field: sortField, order: sortOrder } : undefined}
						onSort={onSort}
					/>
				</WhiteBox>
				<div className="mx-4 flex flex-col flex-grow">{children}</div>
				{removeId && (
					<ConfimationBox
						title="Action Confirmation"
						message="Are you sure you want to remove this record?"
						onOk={handleOkRemove}
						onCancel={handleCancelRemove}
						inverted
					/>
				)}
			</div>
		</WrapperPage>
	);
};

export default GenericCRUDPage;
