import React, { useEffect, useState } from 'react';
import Select from '../components/Select';
import useCRUDQuery from '../hooks/api/useCRUDQuery';
import { ISP } from '../shared/models/ISP';
import StickyBottom from '../components/StickyBottom';
import Button from '../components/Button';
import DataTable, { DataTableField } from '../components/DataTable';
import { NAS, NASConnection } from '../shared/models/infra/NAS';
import WhiteBox from '../components/WhiteBox';

import { UilServerNetwork } from '@iconscout/react-unicons';
import useAPINAS from '../hooks/api/useAPINAS';
import useAPIISPImport from '../hooks/api/useAPIISPImport';
import { Currency } from '../shared/models/currency';
import Logs from '../components/Logs';
import ConfimationBox from '../slices/ConfirmationBox';
import { useNavigate } from 'react-router-dom';
import Input from '../components/Input';
import WrapperPage from './WrapperPage';

function Status({ status, loading }: { status?: NASConnection; loading: boolean }) {
	const classes = !status
		? `text-light-extra ${loading ? 'animate-pulse' : ''}`
		: status.connection[0]
			? 'text-success'
			: 'text-danger';
	return (
		<div className="flex items-center gap-2">
			<UilServerNetwork className={classes} />
			{status && <span>{status.connection[1] || 'Connected'}</span>}
		</div>
	);
}

export default function ISPImportPage() {
	const navigate = useNavigate();

	const [selectedISP, setSelectedISP] = useState<ISP>();
	const [selectedNAS, setSelectedNAS] = useState<NAS[]>([]);
	const [selectedCurrency, setSelectedCurrency] = useState<Currency>();
	const [confirmWipe, setConfirmWipe] = useState<boolean>(false);

	const { loading, response } = useCRUDQuery<ISP>('/isp');
	const { response: currencyResponse } = useCRUDQuery<Currency>('/currency');
	const {
		loading: nasLoading,
		response: nasResponse,
		setCondition,
	} = useCRUDQuery<NAS>('/infra/nas', {
		emptyQueryFetch: false,
	});

	const [ispConnectionStatusId, setISPConnectionStatusId] = useState<string>();
	const {
		loading: apinasLoading,
		connectionStatus,
		refresh,
		clear,
	} = useAPINAS(ispConnectionStatusId || null, {
		mustFetchConnectionStatus: true,
	});
	const { importLogs, fetchImport, importLoading, wipeLoading, fetchWipe } = useAPIISPImport();

	function handleTestConnection() {
		if (!selectedISP) return;
		setISPConnectionStatusId(selectedISP.id);
		if (selectedISP.id === ispConnectionStatusId) {
			clear();
			refresh();
		}
	}

	function handleImport() {
		if (!selectedISP) return;
		if (!connectionStatus) return;

		// Filter connectionStatus to include only selectedNAS
		const selectedConnectionStatus = connectionStatus.filter((cs) =>
			selectedNAS.some((nas) => nas.id === cs.nas_id),
		);

		if (selectedConnectionStatus.filter((cs) => !cs.connection[0]).length > 0) {
			alert('All the selected NAS must be accessible in order to run the import.');
			return;
		}

		if (!selectedCurrency) {
			alert('Select a currency before importing!');
			return;
		}

		fetchImport(selectedISP.id, {
			currency_id: selectedCurrency.id,
			nas: selectedNAS.map((nas) => nas.id),
		});
	}

	function handleSelectNAS(row: NAS) {
		return (value: string) => {
			console.log(selectedNAS, value);
			if (!nasResponse) return;

			const nas = nasResponse.rows.find((nas) => nas.id === row.id);
			if (!nas) return;

			if (value === 'true') {
				console.log('adding');
				setSelectedNAS([...selectedNAS, nas]);
			} else {
				console.log('removing', row);
				setSelectedNAS(nasResponse.rows.filter((nas) => nas.id !== row.id));
			}
		};
	}

	function handleImportFromTxt(nasId: string) {
		if (!selectedISP) return;
		if (!selectedCurrency) {
			alert('Select a currency before importing!');
			return;
		}

		navigate(`/isp-import/${selectedISP.id}/${nasId}/${selectedCurrency.id}`);
	}

	function handleWipe() {
		if (!selectedISP) return;
		setConfirmWipe(true);
	}

	function handleWipeOk() {
		if (!selectedISP) return;
		setConfirmWipe(false);
		fetchWipe(selectedISP.id, selectedISP.name);
	}

	useEffect(() => {
		if (!selectedISP) return;
		setCondition({ operator: { field: 'isp_id', op: 'eq' }, value: selectedISP.id });
		clear();
	}, [selectedISP]);

	return (
		<WrapperPage title="ISP Import Tool">
			<div className="flex flex-col flex-grow justify-between">
				<div>
					<div className="m-4 flex flex-col gap-4">
						<Select
							options={response?.rows.map((isp) => ({ key: isp.id, label: isp.name })) || []}
							disabled={loading}
							placeholder={loading ? 'Loading...' : 'Select an ISP to check routers'}
							value={selectedISP?.id || ''}
							onChange={(key) => setSelectedISP(response?.rows.find((isp) => isp.id === key))}
						/>
						<WhiteBox>
							<DataTable
								style="condensed"
								loading={nasLoading}
								fields={
									[
										{
											title: '',
											property: 'check',
											extractor: (row) => (
												<Input
													type="checkbox"
													value={String(
														!!selectedNAS?.find((nas) => nas.id === row.id) || false,
													)}
													onChange={handleSelectNAS(row)}
												/>
											),
										},
										{
											title: 'Status',
											property: 'status',
											extractor: (row) => (
												<Status
													status={connectionStatus?.find((nas) => nas.nas_id === row.id)}
													loading={apinasLoading}
												/>
											),
										},
										{ title: 'Name', property: 'name' },
										{ title: 'IP', property: 'api_url' },
										{ title: 'Username', property: 'username' },
										{
											title: 'Actions',
											property: 'action',
											extractor: (row) => (
												<Button
													text="import from .txt"
													variant="link"
													onClick={() => handleImportFromTxt(row.id)}
												/>
											),
										},
									] as DataTableField<NAS>[]
								}
								rows={nasResponse?.rows ? nasResponse.rows : []}
							/>
						</WhiteBox>
						{(importLoading || importLogs) && (
							<WhiteBox className={importLoading ? 'animate-pulse' : ''}>
								<Logs logs={importLogs?.map((line) => `${line}\n`) || ['INFO: Importing...']} />
							</WhiteBox>
						)}
					</div>
				</div>
				<StickyBottom>
					<Button
						text="Test Connection"
						variant="light"
						icon={apinasLoading ? { type: 'UilSpinner', spin: true } : 'UilLaptopConnection'}
						onClick={handleTestConnection}
						style="roundedOutline"
						disabled={apinasLoading || importLoading || !nasResponse?.rows}
					/>
					<Button
						text="Import data"
						variant="danger"
						icon={importLoading ? { type: 'UilSpinner', spin: true } : 'UilLaptopConnection'}
						onClick={handleImport}
						style="roundedOutline"
						disabled={importLoading || !nasResponse?.rows || !connectionStatus}
					/>
					<div className="w-64">
						<Select
							options={
								currencyResponse?.rows.map((currency) => ({
									key: currency.id,
									label: currency.name,
								})) || []
							}
							disabled={loading}
							placeholder={loading ? 'Loading...' : 'Select a Currency before import'}
							value={selectedCurrency?.id || ''}
							onChange={(key) =>
								setSelectedCurrency(currencyResponse?.rows.find((currency) => currency.id === key))
							}
						/>
					</div>
					<Button
						text="Wipe data"
						variant="danger"
						icon={wipeLoading ? { type: 'UilSpinner', spin: true } : 'UilTrashAlt'}
						onClick={handleWipe}
						style="roundedOutline"
						disabled={wipeLoading || !selectedISP}
					/>
					{confirmWipe && selectedISP && (
						<ConfimationBox
							title="Action Confirmation"
							message={`Are you sure you want to terminate this subscription? Type ${selectedISP.name} to confirm!`}
							confirmationText={selectedISP.name}
							onOk={handleWipeOk}
							onCancel={() => setConfirmWipe(false)}
						/>
					)}
				</StickyBottom>
			</div>
		</WrapperPage>
	);
}
