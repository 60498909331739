import React, { useEffect } from 'react';

import { CRUDFormGroupType } from '../../components/CRUDFormGroup';
import { CRUDFormFieldAutoComplete } from '../../components/CRUDFormField';
import { Currency } from '../../shared/models/currency';
import { applyMask, undoMask } from '../../components/Input';
import { Plan, PlanType } from '../../shared/models/service/Plan';
import { CRUDFormGroups } from '../../components/CRUDFormGroups';
import { Type } from '../../shared/models/service/Type';
import { PaymentFrequency } from '../../shared/models/service/PaymentFrequency';
import { generateRandomId } from '../../shared/utils';
import GenericCRUDFormPage from '../GenericCRUDFormPage';
import { BeforeSubmitType, useCRUDFormPage } from '../../hooks/useCRUDForm';

const groups: Array<CRUDFormGroupType | Array<CRUDFormGroupType>> = [
	{
		key: 'basic-data',
		title: 'Basic Data',
		rows: [
			{
				key: 'basic-1',
				fields: [{ property: 'name', label: 'Name', highlight: true, proportion: 'w-full' }],
			},
			{
				key: 'basic-2',
				fields: [{ property: 'description', label: 'Description', proportion: 'w-full', multiline: 'h-28' }],
			},
		],
	},
	{
		key: 'payment-data',
		title: 'Finance',
		rows: [
			{
				key: 'finance-1',
				fields: [
					{
						property: 'recurrency_interval',
						label: 'Recurrency',
						proportion: 'w-1/3',
						highlight: true,
						options: Object.values(PaymentFrequency).map((value) => ({ key: value, label: value })),
					},
					{
						property: 'currency_id',
						label: 'Currency',
						proportion: 'w-1/3',
						highlight: true,
						autoComplete: {
							relationship: 'currency',
							quick: true,
							size: 10,
							endpoint: '/currency',
							searchField: 'name',
							parseKey: (row: Currency) => row.id,
							parseDisplay: (row: Currency) => row.name,
						},
					} as CRUDFormFieldAutoComplete<Plan, Currency>,
					{
						property: 'price',
						label: 'Price',
						highlight: true,
						proportion: 'w-1/3',
						type: 'number',
						mask: 'decimal',
					},
				],
			},
		],
	},
];

const createUrl = '/service/plans/new';

function handleAfterFetch(data: Plan): object {
	const form = { ...data, price: '' };
	form.price = applyMask((data.price as number).toString());

	return form;
}

function handleBeforeSubmit(payload: BeforeSubmitType, form: Plan) {
	payload.price = payload.price ? undoMask(payload.price as string) || 0 : 0;
	payload.service_types = form.service_plan_types
		.map((planType) => planType.service_type_id)
		.filter((planType) => planType);
	return payload;
}

function PlanByIdPage() {
	const { form, setForm, handleSave, loading, error } = useCRUDFormPage(
		'/service/plan',
		groups,
		createUrl,
		handleAfterFetch,
		handleBeforeSubmit,
	);

	useEffect(() => {
		const plan = form as Plan;
		if (!plan.service_plan_types || plan.service_plan_types.length === 0) {
			handleAddType();
		}

		if (!plan.recurrency_interval) {
			plan.recurrency_interval = PaymentFrequency.MONTHLY;
			setForm({ ...plan });
		}
	}, [form]);

	function handleRemoveType(id: string) {
		const plan = form as Plan;
		if (!plan?.service_plan_types) return [];

		plan.service_plan_types = plan.service_plan_types.filter((planType) => planType.id !== id);

		setForm({ ...plan });
	}

	function handleAddType() {
		const plan = form as Plan;
		const planType: PlanType = {
			id: generateRandomId(12),
			service_plan_id: '',
			service_type_id: '',
			service_type: {
				id: '',
				name: '',
				description: '',
			},
		};

		if (!plan.service_plan_types) {
			plan.service_plan_types = [];
		}

		plan.service_plan_types.push(planType);

		setForm({ ...plan });
	}

	function handleValueExtractor(planType: PlanType) {
		return (form: Plan) => {
			return (form as Plan).service_plan_types.find((item) => item.id === planType.id)?.service_type.name || '';
		};
	}

	function handleRelationshipResolveValue(planType: PlanType) {
		return (form: Plan) => {
			const relationship: Type | undefined = form.service_plan_types.find(
				(item) => item.id === planType.id,
			)?.service_type;

			if (!relationship) throw Error('Plan/Type relationship cannot be undefined');

			return relationship;
		};
	}

	function handleRelationshipSelect(plan: Plan, planType: PlanType) {
		return (selected: Type, key: string) => {
			const relationship = (form as Plan).service_plan_types.find((item) => item.id === planType.id);

			if (!relationship) throw Error('Plan/Type relationship cannot be undefined');

			relationship.service_type = selected;
			relationship.service_type_id = key;

			setForm({ ...plan });
		};
	}

	function buildTypesGroup(): Array<CRUDFormGroupType | CRUDFormGroupType[]> {
		const plan = form as Plan;
		if (!plan.service_plan_types) return [];

		const typesGroup: CRUDFormGroupType = {
			key: 'types-data',
			title: 'Service Types',
			action: {
				icon: 'UilPlus',
				action: handleAddType,
			},
			rows: plan.service_plan_types.map((planType) => ({
				key: `types-${planType.id}`,
				fields: [
					{
						property: `service_plan_types[${planType.id}]`,
						valueExtractor: handleValueExtractor(planType),
						proportion: 'w-full',
						actionIcon: {
							type: 'UilTrashAlt',
							color: 'text-light',
							action: () => handleRemoveType(planType.id),
						},
						autoComplete: {
							relationship: {
								resolveValue: handleRelationshipResolveValue(planType),
								select: handleRelationshipSelect(plan, planType),
							},
							endpoint: '/service/type',
							quick: true,
							size: 10,
							searchField: 'name',
							parseKey: (row: Type): string => row.id,
							parseDisplay: (row: Type): string => row.name,
						},
					} as CRUDFormFieldAutoComplete<Plan, Type>,
				],
			})),
		};

		return [typesGroup];
	}

	return (
		<GenericCRUDFormPage
			title="Plans"
			groups={groups}
			backwardUrl="/service/plans"
			createUrl={createUrl}
			form={form as never}
			setForm={setForm}
			loading={false}
			handleSave={handleSave}
			error={error}
		>
			<CRUDFormGroups form={form as never} groups={buildTypesGroup()} setForm={setForm} loading={loading} />
		</GenericCRUDFormPage>
	);
}

export default PlanByIdPage;
